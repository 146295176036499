/** @jsx jsx */
import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { css, jsx, useTheme } from '@emotion/react'

const Footer = () => {
    const theme = useTheme()

    const { contentfulContact } = useStaticQuery( graphql`
    query {
        contentfulContact {
            email
          }
    }
  ` )

    const stylez = css`
        margin-top: 8rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 100;
        letter-spacing: 1px;

        ${ theme.breakpoints.mobile } {
            font-size: ${ theme.fontSizes.small };
            width: 40%;
            margin: 8rem auto 0 auto;
            p {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
            }
            }
        a {
            display: inline;
            margin-left: 0.5rem;
            color: ${ theme.colors.secondary };
            :visited {
                color: ${ theme.colors.secondary };
            }
            :hover {
                text-decoration: underline;
            }
        }
        span {
            margin: 0 1rem;
            color: ${ theme.colors.secondary };
            font-weight: 100;
            ${ theme.breakpoints.mobile } {
                display: none;
            }
        }
    `

    return (
        <div
          className='footer'
          css={ stylez }
        >

            <p>
                <a
                  href={ `mailto:${ contentfulContact.email }` }
                  className='email'
                >
                    { contentfulContact.email }
                </a>
                <span>|</span>
                <Link to='/impressum'>
                        Impressum
                </Link>
            </p>

        </div>
    )
}

export default Footer
