import React, { useEffect, useState, useRef } from 'react'
import 'typeface-inter'
import { ThemeProvider, Global, css } from '@emotion/react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { theme } from '../styles/theme'
import '../styles/styles.css'
import '../styles/fonts.css'

const Layout = ( { children } ) => {
    useEffect( () => {
        // disable right click on images
        document.addEventListener( 'contextmenu', function ( e ) {
            if ( e.target.nodeName === 'IMG' ) {
                e.preventDefault()
            }
        }, false )
    }, [] )

    return (
        <div
          className='layout'
        >
            <Global
              styles={ css`
              .layout {
                ${ theme.breakpoints.mobile } {
                    width: 95%;
                }
                ${ theme.breakpoints.tablet } {
                    width: 100%;
                }
              }
              .content {
            height: 100%;
            margin-top: 10rem;
            letter-spacing: 1px;
            ${ theme.breakpoints.tablet } {
                margin: 0 auto;
                margin-top: 8rem;
                width: 85%;
            }
            /* @media only screen and (min-width: 470px) and (max-width: 870px) and (orientation: landscape) {
                        margin-top: 2rem;
                  } */
            }
        ` }
            />
            <ThemeProvider theme={ theme }>
                <Header />
                <div
                  className='content'
                >
                    {children}
                </div>
                <Footer />
            </ThemeProvider>
        </div>
    )
}

export default Layout
