import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react'
import useDocumentScrollThrottled from '../customHooks/useDocumentScrollThrottled'

const Header = () => {
    const theme = useTheme()
    const [ shouldHideHeader, setShouldHideHeader ] = useState( false )
    // const [ shouldShowShadow, setShouldShowShadow ] = useState( false )

    const MINIMUM_SCROLL = 80
    const TIMEOUT_DELAY = 400

    useDocumentScrollThrottled( ( callbackData ) => {
        const { previousScrollTop, currentScrollTop } = callbackData
        const isScrolledDown = previousScrollTop < currentScrollTop
        const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL

        // setShouldShowShadow( currentScrollTop > 2 )

        setTimeout( () => {
            setShouldHideHeader( isScrolledDown && isMinimumScrolled )
        }, TIMEOUT_DELAY )
    } )

    const { site } = useStaticQuery( graphql`
    query {
      site {
        data: siteMetadata {
          menu {
            name
            to
          }
        }
      }
    }
  ` )

    const stylez = css`
        margin-bottom: 4rem;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 90%;
        padding: 2rem 0 1rem 0;
        background-color: white;
        z-index: 9;
        transform: translateY(0);
        transition: transform 0.3s ease-in-out;
        ${ theme.breakpoints.mobile } {
            width: 95%;
            padding: 1rem 0;
            margin-bottom: 2rem;
            ${ shouldHideHeader ? 'transform: translateY(-110%);' : '' }
        }
        ${ theme.breakpoints.tablet } {
            width: 95%;
            padding: 1rem 0;
            margin-bottom: 2rem;

        }
        /* @media only screen and (min-width: 470px) and (max-width: 870px) and (orientation: landscape) {
                    position: relative;
                  } */

      .navi {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        ${ theme.breakpoints.mobile } {
            justify-content: center;
            a {
                width: 100%;
                text-align: center;
              }
            }

        .menu_desktop {
          .menu_desktop_link {
            margin-left: 2rem;
            font-size: 1.5rem;
            font-weight: 100;
            letter-spacing: 2px;
            height: 3rem;
            padding-bottom: 5px;
            ${ theme.breakpoints.mobile } {
            margin-left: 0;
            margin-right: 2rem;
            font-size: 1.5rem;
            }
            ${ theme.breakpoints.tablet } {
            font-size: 1.5rem;
            }
            ${ theme.breakpoints.largeDesktop } {
            font-size: ${ theme.fontSizes.large };
            }
            :hover {
              border-bottom: 1px solid black;
              padding-bottom: 5px;
            }
          }
          .menu_desktop_link_active {
            border-bottom: 1px solid black;
            ${ theme.breakpoints.mobile } {
              border-bottom: 1px solid black;
            }
          }
        }
      }

      .owner_name {
        font-family: ${ theme.fonts.secondary };
        font-size: 2rem;
        font-weight: 100;
        letter-spacing: 4px;
            ${ theme.breakpoints.mobile } {
            font-size: 1.8rem;
            margin-bottom: 1rem;
            width: 100%;
            }
            ${ theme.breakpoints.tablet } {
            /* font-size: 1.5rem; */
            }
            ${ theme.breakpoints.largeDesktop } {
            font-size: 2.5rem;
            }
      }
  `

    return (
        <header
          className='header'
          css={ stylez }
        >

            <div className='navi'>
                <Link
                  to='/'
                >
                    {/* <img alt='Logo' className='' src='logo.svg' /> */}
                    <h1 className='owner_name'>
                      SARAH WILTSCHEK
                    </h1>
                </Link>

                <div className='menu_desktop'>
                    {site.data.menu.map( ( link, key ) => (
                        <Link
                          key={ `menu_desktop_link${ key }` }
                          className='menu_desktop_link'
                          activeClassName='menu_desktop_link_active'
                          to={ link.to }
                        >
                            {link.name}
                        </Link>
                    ) )}
                </div>

            </div>

            {/* <button
              className='hamburger_menu'
              onClick={ () => setIsMenuOpen( true ) }
              aria-label='Open Menu'
            >
                <FaBars className='hamburger_icon' />
            </button> */}

            {/* <MenuMobile
              isOpen={ isMenuOpen }
              setIsOpen={ setIsMenuOpen }
              links={ site.data.menu }
            /> */}

        </header>
    )
}

export default Header
